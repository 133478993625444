.selectric-wrapper {
  padding: 2px 0px 4px;
  margin: -2px 0px -4px;
  cursor: pointer;
  position: relative;
}

.selectric-responsive {
  width: 100%;
}

.selectric-open {
  z-index: 9999;
}

  .selectric {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .selectric .label {
    white-space: nowrap;
    overflow: hidden;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .selectric .button {
    color: transparent;
    font-size: 0px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 20px rgba(98, 217, 255, .7);
    width: 26px;
    min-width: 26px;
    height: 26px;
    margin: 0px 0px 0px 15px;
    position: relative;

    -webkit-transition: box-shadow .2s ease 0s;
    -moz-transition: box-shadow .2s ease 0s;
    -o-transition: box-shadow .2s ease 0s;
    transition: box-shadow .2s ease 0s;
  }
    .selectric-open .button {
      box-shadow: 0px 4px 36px rgba(98, 217, 255, .7);
    }

    .selectric .button:after {
      content: "";
      display: block;
      background: url('/i/dropmarker-icon--hover.svg') no-repeat 50% 50%/10px auto transparent;
      position: absolute;
      top: 1px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      margin: auto;

      -webkit-transition: -webkit-transform .2s ease 0s, -moz-transform .2s ease 0s, -ms-transform .2s ease 0s, -o-transform .2s ease 0s, transform .2s ease 0s;
      -moz-transition: -webkit-transform .2s ease 0s, -moz-transform .2s ease 0s, -ms-transform .2s ease 0s, -o-transform .2s ease 0s, transform .2s ease 0s;
      -o-transition: -webkit-transform .2s ease 0s, -moz-transform .2s ease 0s, -ms-transform .2s ease 0s, -o-transform .2s ease 0s, transform .2s ease 0s;
      transition: -webkit-transform .2s ease 0s, -moz-transform .2s ease 0s, -ms-transform .2s ease 0s, -o-transform .2s ease 0s, transform .2s ease 0s;
    }
      .selectric-open .button:after {
        top: 0px;

        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

  .selectric-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  .selectric-hide-select select {
    position: absolute;
    left: -100%;
  }

  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .selectric-hide-select.selectric-is-native select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0;
  }

  .selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
  }

  .selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
  }

  /* Items box */
  .selectric-items {
    background: #fff;
    border-radius: 0px;
    box-shadow: 0px 4px 20px rgba(98, 217, 255, .7);
    position: absolute;
    width: auto !important;
    min-width: 100%;
    top: 100%;
    left: 50%;
    padding: 5px 0px;
    margin: 1px 0px 0px;
    z-index: -1;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    -moz-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    -o-transition: visibility 0s ease .2s, opacity .2s ease 0s;
    transition: visibility 0s ease .2s, opacity .2s ease 0s;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
    .selectric-above .selectric-items {
      top: auto;
      bottom: 100%;
    }

    .selectric-open .selectric-items {
      visibility: visible;
      opacity: 1;

      -webkit-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      -moz-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      -o-transition: visibility 0s ease 0s, opacity .2s ease 0s;
      transition: visibility 0s ease 0s, opacity .2s ease 0s;
    }

    .selectric-items .selectric-scroll {
      height: 100%;
      overflow: auto;
    }

    .selectric-items ul,
    .selectric-items li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
      .selectric-items li {
        display: block;
        padding: 3px 8px;
        cursor: pointer;

        -webkit-transition: color .2s ease 0s;
        -moz-transition: color .2s ease 0s;
        -o-transition: color .2s ease 0s;
        transition: color .2s ease 0s;
      }
        .selectric-items li:hover,
        .selectric-items li.selected {
          color: #2884ff;
        }
        .selectric-items li.selected {
          font-weight: 500;
        }

        .selectric-items .disabled {
          filter: alpha(opacity=50);
          opacity: 0.5;
          cursor: default !important;
          background: none !important;
          color: #555 !important;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
        }

      .selectric-items .selectric-group .selectric-group-label {
        background: none;
        font-weight: bold;
        padding-left: 15px;
        cursor: default;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }

      .selectric-items .selectric-group.disabled li {
        filter: alpha(opacity=100);
        opacity: 1;
      }

      .selectric-items .selectric-group li {
        padding-left: 15px;
      }

/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
  overflow-x: hidden;
}

.semibold, .semibold_a, .semibold_a * { font-weight: 500; }

span.required { color: #ef0700; font-weight: 500; }

.bgcred, .bgcred_h:hover { background-color: #ef0700; }
.bgcred_i, .bgcred_h_i:hover { background-color: #ef0700 !important; }
.bgclblue1, .bgclblue1_h:hover { background-color: #f4f9fd; }
.bgclblue1_i, .bgclblue1_h_i:hover { background-color: #f4f9fd !important; }
.bgclblue2, .bgclblue2_h:hover { background-color: #dff1ff; }
.bgclblue2_i, .bgclblue2_h_i:hover { background-color: #dff1ff !important; }
.bgcblue, .bgcblue_h:hover { background-color: #2884ff; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #2884ff !important; }
.bgcdblue1, .bgcdblue1_h:hover { background-color: #0066cc; }
.bgcdblue1_i, .bgcdblue1_h_i:hover { background-color: #0066cc !important; }
.bgcdblue2, .bgcdblue2_h:hover { background-color: #020047; }
.bgcdblue2_i, .bgcdblue2_h_i:hover { background-color: #020047 !important; }

.cred, .cred_h:hover { color: #ef0700; }
.cred_i, .cred_h_i:hover { color: #ef0700 !important; }
.clblue1, .clblue1_h:hover { color: #f4f9fd; }
.clblue1_i, .clblue1_h_i:hover { color: #f4f9fd !important; }
.clblue2, .clblue2_h:hover { color: #dff1ff; }
.clblue2_i, .clblue2_h_i:hover { color: #dff1ff !important; }
.cblue, .cblue_h:hover { color: #2884ff; }
.cblue_i, .cblue_h_i:hover { color: #2884ff !important; }
.cdblue1, .cdblue1_h:hover { color: #0066cc; }
.cdblue1_i, .cdblue1_h_i:hover { color: #0066cc !important; }
.cdblue2, .cdblue2_h:hover { color: #020047; }
.cdblue2_i, .cdblue2_h_i:hover { color: #020047 !important; }

.brcred, .brcred_h:hover { border-color: #ef0700; }
.brcred_i, .brcred_h_i:hover { border-color: #ef0700 !important; }
.brclblue1, .brclblue1_h:hover { border-color: #f4f9fd; }
.brclblue1_i, .brclblue1_h_i:hover { border-color: #f4f9fd !important; }
.brclblue2, .brclblue2_h:hover { border-color: #dff1ff; }
.brclblue2_i, .brclblue2_h_i:hover { border-color: #dff1ff !important; }
.brcblue, .brcblue_h:hover { border-color: #2884ff; }
.brcblue_i, .brcblue_h_i:hover { border-color: #2884ff !important; }
.brcdblue1, .brcdblue1_h:hover { border-color: #0066cc; }
.brcdblue1_i, .brcdblue1_h_i:hover { border-color: #0066cc !important; }
.brcdblue2, .brcdblue2_h:hover { border-color: #020047; }
.brcdblue2_i, .brcdblue2_h_i:hover { border-color: #020047 !important; }

p { margin-bottom: 22px; }
p.before-ul { margin-bottom: 12px; }
p + p, .subheader + p, .subsubheader + p { margin-top: -6px; }


.link {
  color: #2884ff;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }


.form-block-container {
  background: #fff;
  padding: 40px 50px 50px;
}
  .form-popup-container .form-block-container {
    min-width: 400px;
    max-width: 700px;
  }

  .form-block-container .subsubsubheader {
    font-size: 30px;
    padding: 0px 0px 30px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 35px;
    }
      .form-row-checkbox .form-label {
        font-size: 14px;
        margin: 0px 0px 0px 10px;
        cursor: pointer;
      }
        .form-row-checkbox .form-label label {
          display: block;
          cursor: pointer;
        }

    .form-row-checkbox .form-field-wrapper {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .form-row-checkbox .form-field input {
        display: none;
      }
      .form-row-checkbox .form-field > span {
        content: "";
        color: transparent;
        border: 2px solid #2884ff;
        font-size: 0px;
        line-height: 0px;
        display: block;
        width: 19px;
        min-width: 19px;
        height: 19px;
        position: relative;
        cursor: pointer;
      }
        .form-row-checkbox .form-field > span label {
          content: "";
          color: transparent;
          display: block;
          font-size: 0px;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;
          cursor: pointer;
        }
          .form-row-checkbox .form-field > span label:after {
            content: "";
            display: none;
            background: #2884ff;
            border-radius: 50%;
            width: 9px;
            height: 9px;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
          }
            .form-row-checkbox .form-field input:checked + span label:after {
              display: block;
            }

      .input-container {
        border-bottom: 1px solid #020047;
        padding: 2px 0px 4px;
        position: relative;
        /*z-index: 1;*/
      }
        .input-container input,
        .input-container textarea,
        .input-container select {
          color: #505050;
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .errorMessage {
          color: #fff;
          background: #ef0700;
          font-size: 12px;
          line-height: 1.3;
          padding: 3px 5px 2px;
          margin: 2px 0px 0px;
        }

    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 5px 0px 0px -25px;
    }
      .form-button {
        padding: 20px 0px 0px 25px;
      }



.button-style {
  display: inline-block;
  color: #0066cc;
  background: none;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  min-width: 100px;
  padding: 14px 25px;
  cursor: pointer;
  position: relative;
}
  .button-style:before {
    content: "";
    border: 1px solid #0066cc;
    background: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
  }

  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #505050 !important;
    font-weight: 400 !important;
    padding: 14px 25px !important;
    cursor: default;
  }
    .button-style[disabled]:before,
    .button-style:disabled:before,
    .button-style.disabled:before {
      background: transparent !important;
      border: 1px solid #909090 !important;
    }

  .button-style:hover {
    font-weight: 500;
    padding: 14px 25px 14px 24px;
  }
    .button-style:hover:before {
      border-width: 2px;
    }

  .button-style.active {
    color: #fff;
  }
    .button-style.active:before {
      background: #0066cc;
    }

  .button-style > * {
    display: block;
    position: relative;
    z-index: 1;
  }


ul.ul, ol.ol {
  margin: -6px 0px 22px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li {
      padding-left: 20px;
    }
      ul.ul li:before {
        content: "●";
        color: transparent;
        display: inline-block;
        background: url('/i/ul-list-style-image.svg') no-repeat 50% 50%/contain transparent;
        width: 9px;
        margin: 0px 10px 0px -19px;
      }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      /*color: #f7ab15;*/
      font-weight: 500;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before,
  a.zoom-image:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;

    -webkit-transition: opacity .2s ease-in-out 0s;
    -moz-transition: opacity .2s ease-in-out 0s;
    -o-transition: opacity .2s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s;
  }

  a.zoom-image:before {
    background: #5698de;
    mix-blend-mode: multiply;
  }
    a.zoom-image:hover:before {
      opacity: 0.7;
    }

  a.zoom-image:after {
    background: url('/i/gallery-image-zoom.svg') no-repeat 0% 0%/contain transparent;
    margin: auto;
    width: 15%;
    height: 15%;
    z-index: 1;
  }
    a.zoom-image:hover:after {
      opacity: 1;
    }


.hr {
  font-size: 0px;
  box-sizing: content-box;
  padding: 25px 0px 40px;
}
  .hr:before {
    content: "";
    display: block;
    background: #2884ff;
    height: 1px;
  }
  .hr.hr-min {
    padding-top: 5px;
  }


.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  width: 1276px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}



/* Structure */
  /* Header */
  .header {
    border-bottom: 1px solid #f4f9fd;
    padding: 6px 0px 8px;
    position: relative;
    z-index: 5;
  }
    .main-page .header {
      border-bottom: none;
    }

    .header > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
      .header-logo {
        font-size: 0px;
        padding: 15px 0px;
      }
        .header-logo img {
          max-width: 90px;
        }


      .header-menu-wrapper {
        flex: 1 1 auto;
        padding: 0px 0px 0px 15px;
      }
        .header-menu {
          position: relative;
        }
          .header-menu-button-container,
          .header-menu-checkbox {
            display: none;
          }

          .header-menu > ul {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
          }
            .header-menu ul > li {
              position: relative;
              z-index: 0;
            }
              .header-menu ul > li:hover {
                z-index: 1;
              }

            .header-menu > ul > li {
              flex: 0 1 auto;
            }

              .header-menu > ul > li > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                height: 100%;
              }
                .header-menu > ul > li.with-dropdown > div,
                .header-menu > ul > li.with-dropdown > div {
                  -webkit-transition: box-shadow .2s ease-in-out 0s;
                  -moz-transition: box-shadow .2s ease-in-out 0s;
                  -o-transition: box-shadow .2s ease-in-out 0s;
                  transition: box-shadow .2s ease-in-out 0s;
                }
                  .header-menu > ul > li.with-dropdown:hover > div,
                  .header-menu > ul > li.with-dropdown:hover > div {
                    box-shadow: 0px 4px 36px 0px rgba(98, 217, 255, .5);
                  }

                .header-menu > ul > li.with-dropdown > div > div,
                .header-menu > ul > li.with-dropdown > div > div {
                  background: transparent;

                  -webkit-transition: background .2s ease-in-out 0s;
                  -moz-transition: background .2s ease-in-out 0s;
                  -o-transition: background .2s ease-in-out 0s;
                  transition: background .2s ease-in-out 0s;
                }
                  .header-menu > ul > li.with-dropdown:hover > div > div,
                  .header-menu > ul > li.with-dropdown:hover > div > div {
                    background: #fff;
                    position: relative;
                    z-index: 1;
                  }

                .header-menu > ul > li > div > div:first-child {
                  width: 100%;
                }
                  .header-menu > ul > li > div > div:first-child > a,
                  .header-menu > ul > li > div > div:first-child > span {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #2884ff;
                    font-size: 20px;
                    line-height: 1.1;
                    font-weight: 300;
                    height: 100%;
                    padding: 17px 18px 18px;
                    text-decoration: none;
                    white-space: nowrap;
                  }
                    .header-menu > ul > li > div > div:first-child > a {
                      cursor: pointer;
                    }
                      .header-menu > ul > li > div > div:first-child > a span {
                        border-bottom: 1px solid transparent;

                        -webkit-transition: border-color .2s ease-in-out 0s;
                        -moz-transition: border-color .2s ease-in-out 0s;
                        -o-transition: border-color .2s ease-in-out 0s;
                        transition: border-color .2s ease-in-out 0s;
                      }

                    .header-menu > ul > li.with-dropdown > div > div:first-child > a,
                    .header-menu > ul > li.with-dropdown > div > div:first-child > span {
                      padding: 17px 8px 18px 18px;
                    }

                    .header-menu > ul > li.item-spec > div > div:first-child > a,
                    .header-menu > ul > li.item-spec > div > div:first-child > span {
                      font-weight: 400;
                    }
                      .header-menu > ul > li > div > div:first-child > a:hover span {
                        border-color: #2884ff;
                      }

                    .header-menu > ul > li.item-spec > div > div:first-child > a,
                    .header-menu > ul > li.item-spec > div > div:first-child > span {
                      color: #ef0700;
                    }
                      .header-menu > ul > li.item-spec > div > div:first-child > a:hover span {
                        border-bottom-color: #ef0700;
                      }

                    .header-menu > ul > li.active > div > div:first-child > a {
                      font-weight: 500;
                    }


                .header-menu > ul > li > div > .dropmarker {
                  content: "";
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: stretch;
                  color: transparent;
                  font-size: 0px;
                  padding: 15px 20px 15px 0px;
                  position: relative;
                }
                  .header-menu > ul > li > div > .dropmarker:after {
                    content: "";
                    background: url('/i/dropmarker-icon.svg') no-repeat 50% 50%/contain transparent;
                    width: 13px;
                    min-width: 13px;
                    height: 11px;
                    position: relative;

                    -webkit-transition: background-image .2s ease-in-out 0s;
                    -moz-transition: background-image .2s ease-in-out 0s;
                    -o-transition: background-image .2s ease-in-out 0s;
                    transition: background-image .2s ease-in-out 0s;
                  }
                    .header-menu > ul > li:hover > div > .dropmarker:after {
                      background-image: url('/i/dropmarker-icon--hover.svg');
                    }

                    .header-menu > ul > li.item-spec > div > .dropmarker:after {
                      background-image: url('/i/dropmarker-icon--red.svg');
                    }
                      .header-menu > ul > li.item-spec:hover > div > .dropmarker:after {
                        background-image: url('/i/dropmarker-icon--hover--red.svg');
                      }


            .header-menu > ul > li > ul {
              background: #fff;
              box-shadow: 0px 4px 36px 0px rgba(98, 217, 255, .5);
              position: absolute;
              top: 100%;
              left: 0px;
              min-width: 100%;
              padding: 12px 12px 15px 12px;
              z-index: 0;

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
              -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
              -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
              transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
            }
              .header-menu > ul > li:hover > ul {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
                -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
                -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
                transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
              }

              .header-menu > ul > li.with-groups > ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
              }
                .header-menu > ul > li.with-groups > ul > li {
                  padding: 0px 30px 5px 0px;
                }
                  .header-menu > ul > li.with-groups > ul > li:not(:last-child) > div,
                  .header-menu > ul > li.with-groups > ul > li:not(:last-child) > ul {
                    border-right: 1px solid #f4f9fd;
                  }


                .header-menu > ul > li > ul div > a,
                .header-menu > ul > li > ul div > span {
                  display: block;
                  line-height: 1.1;
                  padding: 11px 75px 11px 8px;
                  white-space: nowrap;
                }
                .header-menu > ul > li > ul div > a {
                  color: #2884ff;
                  cursor: pointer;

                  -webkit-transition: color .2s ease-in-out 0s;
                  -moz-transition: color .2s ease-in-out 0s;
                  -o-transition: color .2s ease-in-out 0s;
                  transition: color .2s ease-in-out 0s;
                }
                  .header-menu > ul > li > ul li.active div > a {
                    font-weight: 500;
                    cursor: default;
                  }

                  .header-menu > ul > li > ul div > a span,
                  .header-menu > ul > li > ul div > span span {
                    display: inline-block;
                  }

                  .header-menu > ul > li > ul div > a span,
                  .header-menu > ul > li > ul li.active div > a span {
                    border-bottom: 1px solid transparent;

                    -webkit-transition: border-color .2s ease-in-out 0s;
                    -moz-transition: border-color .2s ease-in-out 0s;
                    -o-transition: border-color .2s ease-in-out 0s;
                    transition: border-color .2s ease-in-out 0s;
                  }
                    .header-menu > ul > li > ul div > a:hover span {
                      border-color: #2884ff;
                    }

                  .header-menu > ul > li.with-groups > ul div > a,
                  .header-menu > ul > li.with-groups > ul div > span {
                    padding-right: 35px;
                  }
                  .header-menu > ul > li.with-groups > ul > li > div div > span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: baseline;
                    font-weight: 500;
                  }
                    .header-menu > ul > li.with-groups > ul > li > div div > span:after {
                      content: ":";
                    }

                    .header-menu > ul > li.with-groups > ul > li > ul div > a,
                    .header-menu > ul > li.with-groups > ul > li > ul div > span {
                      padding-top: 6px;
                      padding-bottom: 5px;
                    }


                .header-menu > ul > li > ul .dropmarker {
                  display: none;
                }


      .header-contacts {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 0px;
      }
        .header-contacts > div {
          padding-left: 25px;
        }

        .header-phones {
          padding-bottom: 5px;
        }
          .header-phones > div:first-child a {
            color: #545353;
            font-size: 24px;
            font-weight: 900;
            white-space: nowrap;
          }

          .header-phones > div + div {
            color: #545353;
            font-size: 12px;
          }


        .header-contacts .callback-button .button-style {
          font-size: 12px;
          padding: 8px 18px;
        }
          .header-contacts .callback-button .button-style:hover {
            padding: 8px 18px 8px 17px;
          }
  /* Header end */



  /* Content block */
  .main-page {
    position: relative;
    z-index: 3;
  }

  .content {
    position: relative;
    z-index: 3;
  }
    .main-page .content {
      position: unset;
    }

    .main-page-top-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
    }
      .main-page-top-container:after {
        content: "";
        background: url('/i/arrow-down.svg') no-repeat 50% 50%/18px auto transparent;
        width: 30px;
        height: 120px;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 20px;
        margin: auto;
        animation: main-page-top-arrow-down 1.1s infinite ease-in-out;
        z-index: 1;
      }
        @keyframes main-page-top-arrow-down {
          0% { bottom: 20px }
          78% { bottom: 5px; }
          100% { bottom: 20px }
        }

      .main-page-top-container > div {
        width: 50%;
        min-width: 50%;
      }

        .main-page-top-container > div:first-child > div:first-child {
          background: #f4f9fd;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 35%;
          bottom: 15px;

          -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 75px));
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 75px));

          z-index: 0;
        }
          .main-page-top-container > div:first-child > div:first-child:after {
            content: "";
            background: url('/i/flame-icon.svg') no-repeat 50% 0%/auto 170% transparent;
            position: absolute;
            top: 0px;
            left: -84%;
            right: 0px;
            bottom: 0px;
          }

          .main-page-top-container > div:first-child > div + div {
            width: 200%;
            height: 100%;
            position: relative;
            z-index: 1;
          }
            .main-page-top-container > div:first-child > div + div > .wrapper > div {
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              align-content: flex-start;
              align-items: flex-start;
              padding: 50px 0px 100px;
            }
              .main-page-top-container.with-rotator > div:first-child > div + div > .wrapper > div {
                display: block;
                width: 50%;
                padding-top: 80px;
                padding-right: 80px;
              }

              .main-page-top-container > div:first-child .subheader {
                font-size: 50px;
                font-weight: 900;
                line-height: 1.3;
                text-transform: uppercase;
                width: 50%;
                padding-top: 0px;
                padding-right: 20px;
              }
                .main-page-top-container.with-rotator > div:first-child .subheader {
                  width: unset;
                  padding-right: 0px;
                }

                .main-page-top-container > div:first-child .subheader:after {
                  display: none;
                }


              .main-rotator-form {
                width: 50%;
                padding: 0px 0px 40px 20px;
              }
                .main-page-top-container.with-rotator .main-rotator-form {
                  width: unset;
                  padding-top: 20px;
                  padding-left: 0px;
                }

                .main-rotator-form .form-elements {
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: space-between;
                  align-content: flex-start;
                  align-items: flex-start;
                  margin: -35px 0px 0px -40px;
                }
                  .main-rotator-form .form-elements .form-row {
                    width: 50%;
                    padding: 35px 0px 0px 40px;
                    margin: 0px;
                  }
                  .main-rotator-form .form-elements .form-row-agreement {
                    width: 100%;
                  }


      .main-page-top-container > div + div {
        margin: 0px 0px 55px;
      }
        .main-rotator-container {
          height: 100%;
          position: relative;
          z-index: 1;
        }
          #main-rotator {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            height: 100%;
            position: relative;
            overflow: hidden;

            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 55px));
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 55px));
          }
            #main-rotator .slick-list {
              width: 100%;
            }
            #main-rotator .slick-list,
            #main-rotator .slick-track {
              height: 100%;
            }

            .main-slide-container {
              display: flex !important;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
              height: 100%;
              position: relative;
            }
              #main-rotator > .main-slide-container {
                width: 100%;
                min-width: 100%;
              }

              .main-slide-image {
                width: 50%;
                min-width: 50%;
                position: relative;
                z-index: 1;
              }
                .main-slide-image > div {
                  background: no-repeat 0% 50%/cover transparent;
                  width: 200%;
                  height: 100%;
                  position: relative;

                  -webkit-filter: blur(15px);
                  filter: blur(15px);

                  -webkit-transition: -webkit-filter .3s ease-in-out 0s, filter .3s ease-in-out 0s;
                  -moz-transition: -webkit-filter .3s ease-in-out 0s, filter .3s ease-in-out 0s;
                  -o-transition: -webkit-filter .3s ease-in-out 0s, filter .3s ease-in-out 0s;
                  transition: -webkit-filter .3s ease-in-out 0s, filter .3s ease-in-out 0s;
                }
                  .main-slide-image > div.no-blur {
                    -webkit-filter: blur(0px);
                    filter: blur(0px);
                  }

                  .main-slide-image > div:before {
                    content: "";
                    display: block;
                    height: 0px;
                    padding: 65% 0% 0%;
                  }

                  .main-slide-image span {
                    background: no-repeat 0% 50%/cover transparent;
                    font-size: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    opacity: 0;

                    -webkit-transition: opacity .2s ease-in-out 0s;
                    -moz-transition: opacity .2s ease-in-out 0s;
                    -o-transition: opacity .2s ease-in-out 0s;
                    transition: opacity .2s ease-in-out 0s;
                  }
                    .main-slide-image > div.no-blur span {
                      opacity: 1;
                    }

                  .main-slide-image a {
                    content: "";
                    color: transparent;
                    font-size: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    z-index: 1;
                  }

              .main-slide-code-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                width: 50%;
                min-width: 50%;
              }
                .main-slide-code-container > div {
                  width: 200%;
                  height: 90%;
                  padding: 30px 110px 35px 40px;
                }
                  .main-slide-title {
                    color: #fff;
                    font-size: 40px;
                    font-weight: 500;
                    position: relative;
                    z-index: 2;
                  }
                    .main-slide-title a {
                      color: #fff;
                      text-decoration: none;
                      cursor: pointer;
                    }

                  .main-slide-code {
                    color: #fff;
                    font-size: 18px;
                    margin: 18px 0px 0px;
                    position: relative;
                    z-index: 2;
                  }

            #main-rotator-dots {
              position: absolute;
              top: 80px;
              right: 40px;
              bottom: 80px;
            }
              #main-rotator-dots ul {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                height: 100%;
              }
                #main-rotator-dots li {
                  flex: 1 1 auto;
                  border-right: 1px solid #fff;
                  width: 30px;
                  max-height: 20%;
                  cursor: pointer;
                  overflow: hidden;
                  position: relative;
                }
                  #main-rotator-dots li div {
                    background: #fff;
                    width: 3px;
                    height: 0%;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;

                    -webkit-transition: height .2s ease-in-out 0s;
                    -moz-transition: height .2s ease-in-out 0s;
                    -o-transition: height .2s ease-in-out 0s;
                    transition: height .2s ease-in-out 0s;
                  }
                    #main-rotator-dots li.slick-active div {
                      height: 100%;
                    }
                    #main-rotator-dots li.slick-active div,
                    #main-rotator-dots li:hover ~ li div {
                      top: 0px;
                      bottom: auto;
                    }

                  #main-rotator-dots li:after {
                    content: "";
                    background: #fff;
                    width: 0px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;

                    -webkit-transition: width .2s ease-in-out 0s;
                    -moz-transition: width .2s ease-in-out 0s;
                    -o-transition: width .2s ease-in-out 0s;
                    transition: width .2s ease-in-out 0s;
                  }
                    #main-rotator-dots li:hover:after {
                      width: 3px;
                    }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      line-height: 1.1;
      padding: 15px 0px 0px;
      position: relative;
    }
      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 2px 0px 0px;
      }
        .breadcrumbs li:after,
        .breadcrumbs a,
        .breadcrumbs li > span {
          color: #505050;
          line-height: 1.1;
          vertical-align: baseline;
          text-decoration: none;
        }
        .breadcrumbs li:after {
          content: "/ ";
          margin: auto 4px;
        }
          .breadcrumbs li:last-child:after {
            display: none;
          }
        .breadcrumbs span {
          border-bottom: 1px solid transparent;
        }

        .breadcrumbs a {
          color: #2884ff;
          cursor: pointer;
        }
          .breadcrumbs a > span {
            display: inline-block;
            border-color: #2884ff;

            -webkit-transition: border-color .2s ease-in-out 0s;
            -moz-transition: border-color .2s ease-in-out 0s;
            -o-transition: border-color .2s ease-in-out 0s;
            transition: border-color .2s ease-in-out 0s;
          }
            .breadcrumbs a:hover > span {
              border-color: transparent;
            }


    .subheader,
    .subsubheader,
    .subsubheader-top,
    .subsubheader-clear,
    .subsubsubheader {
      color: #020047;
      font-weight: 700;
      line-height: 1.15;
      margin: 0px;
      position: relative;
      z-index: 2;
    }
      .subheader {
        font-size: 42px;
        padding: 25px 0px 40px;
      }
        .subheader:after {
          content: "";
          background: #f4f9fd;
          display: block;
          width: 70px;
          height: 3px;
        }

      .subsubheader,
      .subsubheader-top,
      .subsubheader-clear {
        font-size: 36px;
        text-align: center;
        padding: 68px 0px 55px;
      }
        .subsubheader:after,
        .subsubheader-top:after {
          content: "";
          background: url('/i/subheader-icon--blue.svg') no-repeat 50% 50%/contain transparent;
          position: absolute;
          top: 40px;
          left: 0px;
          right: 0px;
          bottom: 40px;
          z-index: -1;
        }
        .subsubheader-icon-white:after {
          background-image: url('/i/subheader-icon--white.svg');
        }

      .subheader + div > .subsubheader:first-child,
      .subsubheader-top {
        padding-top: 28px;
      }
        .subheader + div > .subsubheader:first-child:after,
        .subsubheader-top:after {
          top: 0px;
        }

      .subsubheader-clear {
        text-align: left;
        padding: 25px 0px 30px;
      }
        .subsubheader-clear:after {
          display: none;
        }

      .subheader + .subsubheader {
        padding-top: 0px;
      }

      .subsubsubheader {
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        padding: 0px 0px 20px;
      }


    .quick-link-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: baseline;
      line-height: 1.3;
      padding: 0px 0px 30px;
      margin: -12px 0px 0px -15px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 0px 0px;
        margin: 0px 0px 0px 15px;
        cursor: pointer;
      }
        .quick-link-list > div a,
        .quick-link-list > div span {
          color: #2884ff;
          border-bottom: 1px dashed #2884ff;
          white-space: nowrap;

          -webkit-transition: border-color .2s ease-in-out 0s;
          -moz-transition: border-color .2s ease-in-out 0s;
          -o-transition: border-color .2s ease-in-out 0s;
          transition: border-color .2s ease-in-out 0s;
        }
          .quick-link-list > div:hover a,
          .quick-link-list > div:hover span {
            border-color: transparent
          }
          .quick-link-list > div.active a,
          .quick-link-list > div a.active,
          .quick-link-list > div.active span {
            border-color: transparent;
            font-weight: 500;
          }


    .tire-track-bg {
      background: url('/i/tire-track-bg.png') repeat-y 50% 60% transparent;
    }


    .about-block-container {
      background: #f4f9fd;
      padding: 0px 0px 25px;
      margin: 90px 0px 35px;
      position: relative;
    }
      .about-block-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        padding: 0px 0px 30px;
      }
        .about-block-wrapper > div {
          width: 50%;
          min-width: 50%;
        }
          .about-block-wrapper > div > div {
            width: 200%;
            min-width: 200%;
          }
            .about-block-wrapper > div > div > .wrapper > div {
              width: 50%;
              padding: 0px 80px 0px 0px;
            }
              .about-block-wrapper .subsubheader {
                text-align: left;
              }
                .about-block-wrapper .subsubheader:after {
                  display: none;
                }

        .about-block-wrapper:after {
          content: "";
          background: url('/i/about-bg.svg') no-repeat 0% 0%/auto 100% transparent;
          display: block;
          width: 50%;
          min-width: 50%;
          max-height: 525px;
          margin: -90px 0px 0px;
        }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 35px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .gallery-item {
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .gallery-item-width-1 {
          width: 100%;
        }
        .gallery-item-width-2 {
          width: 50%;
        }
        .gallery-item-width-3 {
          width: 33.33333333%;
        }
        .gallery-item-width-4 {
          width: 25%;
        }

        .gallery-item img {
          width: 100%;
        }

      .gallery-notice {
        margin: -10px 0px 0px !important;
        position: relative;
      }


    .list-view {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;

      padding: 0px 0px 35px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .list-view-item {
        width: 25%;
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .list-view-item.empty {
          font-weight: 700;
          width: 100% !important;
        }

        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          position: relative;
        }
          .list-view-item-image {
            margin: 0px 0px 20px;
            position: relative;
            order: 1;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover transparent;
              height: 0px;
              padding: 105% 0% 0%;
              z-index: 1;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-text {
            margin: 0px;
            order: 2;
            z-index: 2;
          }
            .list-view-item-name {
              text-decoration: none;
              position: relative;
              order: 2;
              z-index: 2;
            }
              .list-view-item-name a {
                cursor: pointer;
              }
                .list-view-item-name a:hover,
                .list-view-item-image:hover + .list-view-item-name a,
                .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  text-decoration: underline;
                }

            .list-view-item-notice {
              margin: 18px 0px 0px;
              order: 3;
            }
              .list-view-item-notice :last-child {
                margin-bottom: 0px;
              }


    .service-block-container {
      padding: 0px 0px 15px;
    }
      .service-block-container .list-view-item-wrapper {
        background: #2884ff;
      }
        .service-block-container .list-view-item-image {
          margin: 0px 30px 22px;
        }
          .service-block-container .list-view-item-image a,
          .service-block-container .list-view-item-image span {
            position: relative;
          }
            .service-block-container .list-view-item-image a:after,
            .service-block-container .list-view-item-image span:after {
              content: "";
              background: #0066cc;
              display: block;
              width: 40%;
              height: 15.5%;
              position: absolute;
              right: 0px;
              bottom: 0px;

              -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
              clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);

              z-index: 1;
            }

        .service-block-container .list-view-item-name {
          font-size: 22px;
          line-height: 1.3;
          text-align: center;
          padding: 0px 0px 30px;
        }
          .service-block-container .list-view-item-name a,
          .service-block-container .list-view-item-name span {
            color: #fff;
          }
          .service-block-container .list-view-item-name a {
            border-bottom: 1px solid transparent;

            -webkit-transition: border-color .2s ease-in-out 0s;
            -moz-transition: border-color .2s ease-in-out 0s;
            -o-transition: border-color .2s ease-in-out 0s;
            transition: border-color .2s ease-in-out 0s;
          }
            .service-block-container .list-view-item-name a:hover,
            .service-block-container .list-view-item-image:hover + .list-view-item-name a {
              border-color: #fff;
              text-decoration: none;
            }


    .service-links-container {
      padding: 0px 0px 10px;
    }
      .service-links-container .list-view-item {
        width: auto;
      }
        .service-links-container .list-view-item-wrapper {
          border: 1px solid #2884ff;
        }
          .service-links-container .list-view-item-name {
            font-size: 20px;
            line-height: 1.2;
            text-align: center;
          }
            .service-links-container .list-view-item-name a,
            .service-links-container .list-view-item-name > span {
              display: block;
              color: #2884ff;
              padding: 10px 15px 11px;
              text-decoration: none;
            }

            .service-links-container .list-view-item-name a span {
              border-bottom: 1px solid transparent;

              -webkit-transition: border-color .2s ease-in-out 0s;
              -moz-transition: border-color .2s ease-in-out 0s;
              -o-transition: border-color .2s ease-in-out 0s;
              transition: border-color .2s ease-in-out 0s;
            }
              .service-links-container .list-view-item-name a:hover span,
              .service-links-container .list-view-item-image:hover + .list-view-item-name a span {
                border-color: #2884ff;
              }
              .service-links-container .list-view-item-image:hover + .list-view-item-name a {
                text-decoration: none;
              }


    .portfolio-section-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      padding: 0px 0px 30px;
      margin: -15px 0px 0px -30px;
      z-index: 5;
    }
      .portfolio-section-info > div:first-child {
        width: 9.03522205%;
        min-width: 9.03522205%;
        padding: 15px 0px 0px 30px;
      }
        .portfolio-section-info > div:first-child span {
          font-size: 0px;
          display: block;
          background: no-repeat 50% 50%/contain transparent;
        }
          .portfolio-section-info > div:first-child span:after {
            content: "";
            display: block;
            padding: 100% 0% 0%;
          }


      .portfolio-section-info > div + div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        flex-grow: 1;
      }
        .portfolio-section-info > div + div > div {
          padding: 15px 0px 0px 30px;
        }

        .portfolio-model-select {
          min-width: 25%;
          order: 2;
        }
          .portfolio-model-select select {
            padding: 6px;
          }
          .portfolio-model-select select option.empty,
          .portfolio-model-select .selectric-items li.empty {
            display: none;
          }

        .portfolio-sections-popular-link {
          padding: 0px 0px 12px;
        }
        .portfolio-sections-other-link {
          order: 1;
        }
          .portfolio-sections-other-link span,
          .portfolio-sections-popular-link span {
            color: #020047;
            font-weight: 300;
            border-bottom: 1px dashed #020047;
            cursor: pointer;

            -webkit-transition: border-color .2s ease-in-out 0s;
            -moz-transition: border-color .2s ease-in-out 0s;
            -o-transition: border-color .2s ease-in-out 0s;
            transition: border-color .2s ease-in-out 0s;
          }
            .portfolio-sections-other-link span:hover,
            .portfolio-sections-popular-link span:hover {
              border-color: transparent;
            }


    .portfolio-sections-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      padding: 1px 0px 40px 1px;
    }
      .portfolio-sections-container > div,
      .portfolio-sections-container.not-popular-visible > div.not-popular {
        width: 12.5%;
        margin: -1px 0px 0px -1px;
        visibility: visible;
        z-index: 1;

        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);

        -webkit-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
        -moz-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
        -o-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
        transition: width .2s ease-in-out 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
      }
        .portfolio-sections-container > div.not-popular {
          width: 0%;
          margin-left: 0px;
          visibility: hidden;

          z-index: 0;

          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);

          -webkit-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          -moz-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          -o-transition: width .2s ease-in-out 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          transition: width .2s ease-in-out 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
        }

        .portfolio-sections-container > div > div {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: stretch;
          background: #fff;
          border: 1px solid #dff1ff;
          height: 100%;
        }
          .portfolio-sections-container > div > div > div:first-child,
          .portfolio-sections-container.not-popular-visible > div.not-popular > div > div:first-child {
            flex-grow: 1;
            opacity: 1;

            -webkit-transition: opacity .08s ease .12s;
            -moz-transition: opacity .08s ease .12s;
            -o-transition: opacity .08s ease .12s;
            transition: opacity .08s ease .12s;
          }
            .portfolio-sections-container > div.not-popular > div > div:first-child {
              opacity: 0;

              -webkit-transition: opacity .08s ease 0s;
              -moz-transition: opacity .08s ease 0s;
              -o-transition: opacity .08s ease 0s;
              transition: opacity .08s ease 0s;
            }

            .portfolio-sections-container > div > div > div:first-child a {
              display: block;
              font-size: 16px;
              text-align: center;
              height: 100%;
              padding: 0px 0px 10px;
              cursor: pointer;

              -webkit-transition: color .2s ease-in-out 0s;
              -moz-transition: color .2s ease-in-out 0s;
              -o-transition: color .2s ease-in-out 0s;
              transition: color .2s ease-in-out 0s;
            }
              .portfolio-sections-container > div > div:hover > div:first-child a {
                color: #2884ff;
              }

          .portfolio-sections-container > div > div > div + div a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            font-size: 0px;
            height: 100%;
            padding: 20px 20px 15px;
            opacity: .7;
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'saturate\' values=\'0\'/></filter></svg>#grayscale");
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
            cursor: pointer;

            -webkit-transition: opacity .2s ease-in-out 0s, filter .2s ease-in-out 0s, -webkit-filter .2s ease-in-out 0s;
            -moz-transition: opacity .2s ease-in-out 0s, filter .2s ease-in-out 0s, -webkit-filter .2s ease-in-out 0s;
            -o-transition: opacity .2s ease-in-out 0s, filter .2s ease-in-out 0s, -webkit-filter .2s ease-in-out 0s;
            transition: opacity .2s ease-in-out 0s, filter .2s ease-in-out 0s, -webkit-filter .2s ease-in-out 0s;
          }
            .portfolio-sections-container > div > div > div + div a:hover,
            .portfolio-sections-container > div > div > div:hover + div a {
              opacity: 1;
              -webkit-filter: unset;
              filter: unset;
            }

            .portfolio-sections-container > div > div > div + div a span {
              background: no-repeat 50% 50%/contain transparent;
              width: 100%;
              height: 0px;
              padding: 100% 0% 0%;
            }


    .portfolio-service-links {
      margin-left: -20px;
    }
      .portfolio-service-links:before {
        content: "По видам услуг:";
        font-weight: 500;
        padding: 12px 0px 0px;
        margin: 0px 0px 0px 20px;
      }

      .portfolio-service-links > div {
        margin-left: 20px;
      }
        .portfolio-service-links > div:after {
          content: "";
          color: transparent;
          font-size: 0px;
          background: url('/i/cross-icon.svg') no-repeat 50% 50%/8px auto #fff;
          border-radius: 50%;
          box-shadow: 0px 1px 10px rgba(98, 217, 255, .5);
          width: 0px;
          min-width: 0px;
          height: 22px;
          margin: -2px 0px 0px 8px;
          position: relative;
          visibility: hidden;

          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          transform: scale(0);

          -webkit-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          -moz-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          -o-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out .2s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
        }
          .portfolio-service-links > div.active:after {
            width: 22px;
            min-width: 22px;
            visibility: visible;

            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);

            -webkit-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
            -moz-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
            -o-transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
            transition: width .2s ease-in-out 0s, min-width .2s ease-in-out 0s, box-shadow .2s ease 0s, visibility 0s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
          }
            .portfolio-service-links > div.active:hover:after {
              box-shadow: 0px 1px 20px rgba(98, 217, 255, .5);
            }


    .portfolio-block-container {
      padding: 0px 0px 15px;
    }
      .portfolio-filtering .portfolio-block-container {
        padding-left: 10px;
        padding-right: 10px;
        margin: 0px -10px;
      }

      .portfolio-block-container .list-view-item-wrapper {
        align-items: flex-end;
        padding: 0px 0px 12px;
      }
        .portfolio-block-container .list-view-item-image {
          width: 100%;
          margin: 0% 0% -8%;
        }
          .portfolio-block-container .list-view-item-image > a,
          .portfolio-block-container .list-view-item-image > span {
            background: none;
            height: unset;
            padding: 0px;
          }
            .portfolio-block-container .list-view-item-image > a span:first-child,
            .portfolio-block-container .list-view-item-image > span span:first-child {
              display: block;
              background: no-repeat 50% 50%/cover transparent;
              height: 0px;
              padding: 78% 0% 0%;

              -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
            }

            .portfolio-block-container .list-view-item-image > a span + span,
            .portfolio-block-container .list-view-item-image > span span + span {
              display: block;
              width: 19.736842105%;
              margin: 0%;
              position: absolute;
              left: 5.13157895%;
              bottom: 4%;
            }
              .portfolio-block-container .list-view-item-image > a span + span:after,
              .portfolio-block-container .list-view-item-image > span span + span:after {
                content: "";
                background: url('/i/arrow-right--bold.svg') no-repeat 50% 50%/46.66666666% auto #fff;
                border-radius: 50%;
                box-shadow: 0px 4px 20px 0px rgba(98, 217, 255, .7);
                display: block;
                height: 0px;
                padding: 100% 0% 0%;

                -webkit-transform: translateY(50%);
                -moz-transform: translateY(50%);
                -ms-transform: translateY(50%);
                -o-transform: translateY(50%);
                transform: translateY(50%);

                -webkit-transition: box-shadow .2s ease-in-out 0s;
                -moz-transition: box-shadow .2s ease-in-out 0s;
                -o-transition: box-shadow .2s ease-in-out 0s;
                transition: box-shadow .2s ease-in-out 0s;
              }
                .portfolio-block-container .list-view-item-wrapper:hover .list-view-item-image > a span + span:after {
                  box-shadow: 0px 4px 36px 0px rgba(98, 217, 255, .7);
                }


        .portfolio-block-container .list-view-item-text {
          color: #fff;
          font-size: 14px;
          width: 70%;
          padding: 3px 12px;
          margin: 0px 0px 0px;
          position: relative;
        }
          .portfolio-block-container .list-view-item-text:before {
            content: "";
            background: #2884ff;
            display: block;
            position: absolute;
            top: -6px;
            left: 0px;
            right: 0px;
            bottom: -6px;
            z-index: 0;

            -webkit-transform: skewY(3.25deg);
            -moz-transform: skewY(3.25deg);
            -ms-transform: skewY(3.25deg);
            -o-transform: skewY(3.25deg);
            transform: skewY(3.25deg);
          }
            .portfolio-block-container .list-view-item-name {
              padding: 0px 0px 10px;
            }
              .portfolio-block-container .list-view-item-name a {
                color: #fff;
                border-bottom: 1px solid rgba(255,255,255,.6);

                -webkit-transition: border-color .2s ease-in-out 0s;
                -moz-transition: border-color .2s ease-in-out 0s;
                -o-transition: border-color .2s ease-in-out 0s;
                transition: border-color .2s ease-in-out 0s;
              }
                .portfolio-block-container .list-view-item-name a:hover,
                .portfolio-block-container .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  border-color: #fff;
                  text-decoration: none;
                }

            .portfolio-info-icons,
            .portfolio-info-icons-line {
              padding: 0px 0px 40px;
              margin: -10px 0px 0px -15px;
              position: relative;
              z-index: 1;
            }
              .portfolio-block-container .portfolio-info-icons {
                padding: 6px 0px 10px;
              }

              .portfolio-info-icons > div,
              .portfolio-info-icons-line > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 6px 0px 0px 15px;
              }
                .portfolio-info-icons img,
                .portfolio-info-icons-line img {
                  height: 20px;
                  margin-right: 10px;
                }

                .portfolio-block-container .portfolio-info-icons > div a {
                  color: #fff;
                }

                .portfolio-info-icons .price-item > span,
                .portfolio-info-icons-line .price-item > span {
                  white-space: nowrap;
                }
                  .portfolio-info-icons .price-item > span > span:after,
                  .portfolio-info-icons-line .price-item > span > span:after {
                    content: " ";
                  }


    .portfolio-info-icons-line {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      margin: -6px 0px 0px -22px;
    }
      .portfolio-info-icons-line > div {
        padding-left: 22px;
      }


    .portfolio-rotator {
      padding: 0px 0px 40px;
      overflow: hidden;
    }
      .portfolio-rotator .portfolio-block-container {
        padding: 0px;
      }
        .portfolio-rotator .portfolio-block-container .list-view {
          padding: 0px;
          margin: -20px 0px 0px -182px;
        }
          .portfolio-rotator .slick-list,
          .portfolio-rotator .slick-track {
            margin: 0px;
            overflow: unset;
          }

          .portfolio-rotator .portfolio-block-container .list-view-item {
            width: 33.33333333%;
            padding: 20px 0px 0px 182px;
          }
            .portfolio-rotator .portfolio-block-container .list-view-item-wrapper,
            .portfolio-rotator .portfolio-block-container .slick-current-fix + div .list-view-item-wrapper,
            .portfolio-rotator .portfolio-block-container .slick-current-fix + div + div + div .list-view-item-wrapper {
              margin: 20% 0% 0%;

              -webkit-transition: margin .5s ease-in-out 0s;
              -moz-transition: margin .5s ease-in-out 0s;
              -o-transition: margin .5s ease-in-out 0s;
              transition: margin .5s ease-in-out 0s;
            }
              .portfolio-rotator .portfolio-block-container .slick-current-fix .list-view-item-wrapper,
              .portfolio-rotator .portfolio-block-container .slick-current-fix + div + div .list-view-item-wrapper,
              .portfolio-rotator .portfolio-block-container .slick-current-fix + div + div + div + div .list-view-item-wrapper {
                margin: 0% 0% 20%;
              }

              .portfolio-rotator .portfolio-block-container .list-view-item-image > a span + span,
              .portfolio-rotator .portfolio-block-container .list-view-item-image > span span + span {
                left: auto;
                right: 0px;
                top: 10%;
                bottom: auto;
              }
                .portfolio-rotator .portfolio-block-container .list-view-item-image > a span + span:after,
                .portfolio-rotator .portfolio-block-container .list-view-item-image > span span + span:after {
                  -webkit-transform: translateX(50%);
                  -moz-transform: translateX(50%);
                  -ms-transform: translateX(50%);
                  -o-transform: translateX(50%);
                  transform: translateX(50%);
                }

      .portfolio-rotator-dots ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        width: 75%;
        padding: 35px 0px 0px;
      }
        .portfolio-rotator-dots li {
          flex: 1 1 auto;
          border-bottom: 1px solid #2884ff;
          max-width: 7.5%;
          height: 25px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
        }
          .portfolio-rotator-dots li div {
            background: #2884ff;
            width: 0%;
            height: 3px;
            position: absolute;
            right: 0px;
            bottom: 0px;

            -webkit-transition: width .2s ease-in-out 0s;
            -moz-transition: width .2s ease-in-out 0s;
            -o-transition: width .2s ease-in-out 0s;
            transition: width .2s ease-in-out 0s;
          }
            .portfolio-rotator-dots li.slick-active div {
              width: 100%;
            }
            .portfolio-rotator-dots li.slick-active div,
            .portfolio-rotator-dots li:hover ~ li div,
            .portfolio-rotator-dots.invert-fix li div {
              left: 0px;
              right: auto;
            }
            .portfolio-rotator-dots.invert-fix li.slick-active div {
              left: auto;
              right: 0px;
            }

          .portfolio-rotator-dots li:after {
            content: "";
            background: #2884ff;
            height: 0px;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;

            -webkit-transition: height .2s ease-in-out 0s;
            -moz-transition: height .2s ease-in-out 0s;
            -o-transition: height .2s ease-in-out 0s;
            transition: height .2s ease-in-out 0s;
          }
            .portfolio-rotator-dots li:hover:after {
              height: 3px;
            }


    .portfolio-page {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0px 0px 40px;
    }
      .portfolio-page > div {
        width: 50%;
        min-width: 50%;
      }

        .portfolio-page > div:first-child > div {
          width: 200%;
        }
          .portfolio-page > div:first-child > div > .wrapper > div {
            width: 50%;
            padding: 0px 30px 0px 0px;
          }


      .portfolio-page .portfolio-info-icons {
        padding: 40px 10px 140px 40px;
        margin: 0px;
        position: relative;
      }
        .portfolio-page .portfolio-info-icons:before {
          content: "";
          background: #f4f9fd;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: -1;

          -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 70px));
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 70px));
        }

        .portfolio-page .portfolio-info-icons > div {
          padding: 25px 0px 0px 0px;
        }
          .portfolio-page .portfolio-info-icons img {
            margin-right: 20px;
          }


    .advantages-block-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 0px 40px;
      margin: -30px 0px 0px -30px;
    }
      .advantages-block-container > div {
        width: 25%;
        padding: 30px 0px 0px 30px;
      }
        .advantages-block-container > div > div:first-child {
          background: no-repeat 0% 50%/contain transparent;
        }
          .advantages-block-container > div > div:first-child:after {
            content: "";
            display: block;
            height: 0px;
            padding: 25% 0% 0%;
          }

        .advantages-block-container > div > div + div {
          font-weight: 500;
          padding: 15px 0px 0px;
        }


    .price-vitrine-container {
      padding: 0px 0px 15px;
    }
      .price-vitrine-container .list-view-item-wrapper:after {
        content: "";
        background: #f4f9fd;
        display: block;
        height: 0px;
        padding: 75px 0px 0px;
        margin: -5px 0px 0px;
        position: relative;
        z-index: 0;

        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 66.66666667%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 66.66666667%);
      }
      .price-vitrine-container .list-view-item:nth-child(2n) .list-view-item-wrapper:after {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 66.66666667%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 66.66666667%, 0 100%);
      }

      .price-vitrine-container .list-view-item-wrapper > div {
        background: #f4f9fd;
        padding: 25px 15px 0px;
        position: relative;
        z-index: 1;
      }
        .price-vitrine-container .list-view-item-wrapper > div:before {
          content: "";
          display: block;
          background: url('/i/price-vitrine-bg.svg') repeat-y 50% 0%/100% auto transparent;
          position: absolute;
          top: 28px;
          left: 24px;
          right: 24px;
          bottom: 0;
          z-index: 0;
        }

        .price-vitrine-container .list-view-item-text {
          position: relative;
        }
          .price-vitrine-container .list-view-item-name {
            color: #020047;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.15;
            text-align: center;
            padding: 20px 0px;
          }

          .price-vitrine-container .price-vitrine-item-price {
            color: #2884ff;
            font-size: 46px;
            font-weight: 900;
            line-height: 1.15;
            text-align: center;
            padding: 0px 0px 15px;
          }
            .price-vitrine-container .price-vitrine-item-price span:after {
              content: " ";
            }

          .price-vitrine-container .list-view-item-notice {
            text-align: center;
            padding: 0px 0px 25px;
            margin: 0px;
          }

          .price-vitrine-container .price-vitrine-item-button {
            text-align: center;
          }


    .economy-calc-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      background: #f4f9fd;
      margin: 0px 0px 35px;
    }
      .economy-calc-container > div {
        width: 50%;
      }
        .economy-calc-container > div:first-child > div {
          width: 200%;
        }
          .economy-calc-container > div:first-child > div > .wrapper > div {
            width: 50%;
            padding: 45px 2vw 45px 0px;
            position: relative;
            z-index: 1;
          }
            #economy-calc-form {
              padding: 10px 45px 35px 0px;
            }
              #economy-calc-form fieldset {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: flex-start;
                padding-right: 3vw;
                margin: -35px 0px 0px -35px;
              }
                #economy-calc-form .field-wrapper {
                  width: 100%;
                  padding: 35px 0px 0px 35px;
                }
                #economy-calc-form .field-wrapper.fuel-price,
                #economy-calc-form .field-wrapper.gas-price {
                  width: 50%;
                }

                  #economy-calc-form .form-label {
                    margin: 0px 0px 3px;
                  }
                    #economy-calc-form .field-wrapper.fuel-price .form-label,
                    #economy-calc-form .field-wrapper.gas-price .form-label,
                    #economy-calc-form .field-wrapper.fuel-consumption .form-label {
                      margin-left: 7px;
                    }

                  #economy-calc-form .form-field {
                    margin: 7px 0px 0px;
                  }
                    #economy-calc-form .field-wrapper.fuel-price .form-field,
                    #economy-calc-form .field-wrapper.gas-price .form-field,
                    #economy-calc-form .field-wrapper.fuel-consumption .form-field {
                      padding: 0px 4px;
                    }

                    #economy-calc-form .form-field .input-container {
                      border: none;
                      padding: 13px;
                    }
                      #economy-calc-form .form-field .input-container:before {
                        content: "";
                        background: #fff;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;

                        -webkit-transform: skewX(-9deg);
                        -moz-transform: skewX(-9deg);
                        -ms-transform: skewX(-9deg);
                        -o-transform: skewX(-9deg);
                        transform: skewX(-9deg);
                      }

                      #economy-calc-form .form-field .input-container input {
                        color: #2884ff;
                        font-size: 24px;
                        font-weight: 900;
                        text-align: center;
                        position: relative;
                        z-index: 1;
                      }

                      #economy-calc-form #economy-calc-mileage-slider {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0px 48px;
                      }
                        #economy-calc-form #economy-calc-mileage-slider > span {
                          color: #2884ff;
                          font-size: 24px;
                          font-weight: 900;
                          white-space: nowrap;
                        }

                        #economy-calc-form #economy-calc-mileage-slider > div {
                          width: 100%;
                          height: 40px;
                          margin: 0px 30px;
                          position: relative;
                        }
                          #economy-calc-form #economy-calc-mileage-slider > div:before {
                            content: "";
                            background: #2884ff;
                            height: 2px;
                            margin: auto;
                            position: absolute;
                            top: 0px;
                            left: -10px;
                            right: -10px;
                            bottom: 0px;
                          }

                          #economy-calc-form #economy-calc-mileage-slider > div > span {
                            display: block;
                            background: #fff;
                            border-radius: 50%;
                            box-shadow: 0px 2px 20px 0px rgba(98, 217, 255, .7);
                            width: 40px;
                            height: 40px;
                            margin: auto auto auto -20px;
                            position: absolute;
                            top: 0px;
                            bottom: 0px;
                            cursor: pointer;

                            -webkit-transition: box-shadow .2s ease-in-out 0s;
                            -moz-transition: box-shadow .2s ease-in-out 0s;
                            -o-transition: box-shadow .2s ease-in-out 0s;
                            transition: box-shadow .2s ease-in-out 0s;
                          }
                            #economy-calc-form #economy-calc-mileage-slider > div > span:hover {
                              box-shadow: 0px 2px 36px 0px rgba(98, 217, 255, .7);
                            }

                            #economy-calc-form #economy-calc-mileage-slider > div > span:before {
                              content: "";
                              background: #f4f9fd;
                              border-radius: 50%;
                              position: absolute;
                              width: 12px;
                              height: 12px;
                              margin: auto;
                              position: absolute;
                              top: 0px;
                              left: 0px;
                              right: 0px;
                              bottom: 0px;

                              -webkit-transition: background .2s ease-in-out 0s;
                              -moz-transition: background .2s ease-in-out 0s;
                              -o-transition: background .2s ease-in-out 0s;
                              transition: background .2s ease-in-out 0s;
                            }
                              #economy-calc-form #economy-calc-mileage-slider > div > span:hover:before {
                                background: #dff1ff;
                              }

                            #economy-calc-form #economy-calc-mileage-slider > div > span > span {
                              display: flex;
                              flex-direction: row;
                              justify-content: center;
                              align-items: flex-start;
                              padding: 10px 0px 0px;
                              position: absolute;
                              top: 100%;
                              left: 0px;
                              right: 0px;
                            }
                              #economy-calc-form #economy-calc-mileage-slider > div > span > span span {
                                display: block;
                                line-height: 1;
                                background: #fff;
                                border-radius: 5px;
                                padding: 10px 15px 9px;
                                margin: 0 auto;
                                cursor: default;
                                white-space: nowrap;
                                outline: none;
                                position: relative;
                              }
                              #economy-calc-form #economy-calc-mileage-slider > div > span > span span:after {
                                content: "";
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-bottom: 7px solid #fff;
                                width: 0px;
                                height: 0px;
                                margin: auto;
                                position: absolute;
                                top: -6px;
                                left: 0px;
                                right: 0px;
                              }


              #economy-calc-form .results {
                margin: 0px 0px 0px -35px;
              }
                #economy-calc-form .results > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                #economy-calc-form .results > div:first-child {
                  align-items: flex-start;
                }
                  #economy-calc-form .results > div > div:first-child {
                    flex-shrink: 4;
                  }
                  #economy-calc-form .results > div > div + div {
                    flex-shrink: 5;
                  }

                  #economy-calc-form .results > div:first-child .form-label + div {
                    font-size: 30px;
                  }
                  #economy-calc-form .results > div:first-child > div + div .form-label + div {
                    font-weight: 500;
                  }
                    #economy-calc-form .results > div:first-child .form-label + div span {
                      white-space: nowrap;
                    }

                  #economy-calc-form .results > div + div > div:first-child {
                    color: #2884ff;
                    font-weight: 500;
                  }
                    #economy-calc-form .results > div + div > div:first-child .form-label + div {
                      font-size: 34px;
                      font-weight: 900;
                      line-height: 1.2;
                    }
                      #economy-calc-form .results > div + div > div:first-child .form-label + div span {
                        white-space: nowrap;
                      }

                  #economy-calc-form .results .field-wrapper.button .button-style {
                    width: 100%;
                  }


        .economy-calc-container > div + div > div {
          font-size: 0px;
          background: url('/i/economy-calc-bg.jpg') no-repeat 0% 50%/cover transparent;
          height: 100%;
          margin-left: -5vw;
          position: relative;
        }
          .economy-calc-container > div + div > div:after {
            content: "";
            display: block;
            height: 0px;
            padding: 73% 0% 0%;
          }


    .price-quick-links {
      justify-content: center;
    }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      line-height: 1.15;
      padding: 0px 0px 40px;
      position: relative;
    }
      .price-table > div + div {
        margin-top: 35px;
      }
        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0px 0px 20px 31px;
        }
          .price-table-group span {
            color: #020047;
            font-size: 28px;
            font-weight: 500;
          }

        .price-table-head {
          color: #020047;
          font-size: 24px;
          background: #dff1ff;
          border: 1px solid #dff1ff;
          align-items: flex-end !important;
        }

        .price-table-subheader {
          color: #020047;
          font-size: 21px;
          font-weight: 500;
          background: #f3faff;
          border: 1px solid #dff1ff;
          margin: -1px 0px 0px;
        }

        .price-table-row {
          background: #fff;
          border: 1px solid #dff1ff;
          margin: -1px 0px 0px;
        }
          .price-table-row.even {
            background: #f9fdfd;
          }

          .price-name,
          .price-price {
            padding: 18px 5px;
            flex-basis: 100%;
            flex-shrink: 1;
          }
          .price-name {
            padding-left: 30px;
          }
          .price-price {
            text-align: center;
            flex-shrink: 2;
          }
            .price-price > span:after {
              content: " ";
            }


    .features-bg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0px 0px 50px;
      position: relative;
    }
      .features-bg:before {
        content: "";
        background: url('/i/tire-track-min-bg.png') no-repeat 75% 0%, url('/i/tire-track-min-bg.png') no-repeat 25% 100% transparent;
        display: block;
        position: absolute;
        top: 30px;
        left: 0px;
        right: 0px;
        bottom: 30px;
        z-index: 1;
      }

      .features-bg > div {
        width: 50%;
        min-width: 50%;
      }
      .features-bg > div:first-child {
        padding: 75px 0px 80px;
        position: relative;
      }
        .features-bg > div:first-child:before {
          content: "";
          background: #f4f9fd;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          right: -24.5%;
          bottom: 0px;

          -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 70px), 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 70px), 0 100%);
        }

        .features-bg > div:first-child > div {
          width: 200%;
          position: relative;
          z-index: 2;
        }
          .features-bg > div:first-child > div > .wrapper > div {
            width: 50%;
          }

          .features-block-container {
            padding: 0px 0px 40px;
            position: relative;
            z-index: 2;
          }
            .features-block-container > div {
              padding: 40px 40px 0px;
              margin: -40px -40px 0px;
              overflow: hidden;
            }
              .features-block-container > div > div {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: stretch;
                margin: 0px 0px -1px;
              }
                .features-block-container > div > div > div {
                  border: solid #dff1ff;
                  border-width: 0px 1px 1px 0px;
                  width: 50%;
                  padding: 0px 0px 30px;
                }
                  .features-block-container > div > div > div:nth-child(2n) {
                    border-right-width: 0px;
                    padding-left: 30px;
                  }
                  .features-block-container > div > div > div:nth-child(n+3) {
                    padding-top: 30px;
                  }

                  .features-block-container > div > div > div > div:first-child {
                    border: 7.5px solid #fff;
                    border-radius: 50%;
                    box-shadow: 0px 0px 40px -5px rgba(98, 217, 255, .7), inset 0px 0px 95px -60px rgba(98, 217, 255, .7);
                    width: 59px;
                    height: 59px;
                  }
                  .features-block-container > div > div > div.negative > div:first-child {
                    box-shadow: 0px 0px 40px -5px rgba(239, 7, 0, .5), inset 0px 0px 95px -60px rgba(239, 7, 0, .5);
                  }

                    .features-block-container > div > div > div > div:first-child:after {
                      content: "+";
                      display: inline-flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      color: #fff;
                      font-size: 36px;
                      font-weight: 500;
                      line-height: 1.1;
                      width: 100%;
                      height: 100%;
                      position: relative;
                      z-index: 1;
                    }
                    .features-block-container > div > div > div.negative > div:first-child:after {
                      content: "–";
                    }

                  .features-block-container > div > div > div > div + div {
                    font-size: 24px;
                    line-height: 1.2;
                    padding: 20px 0px 0px;
                  }

                  .features-block-container > div > div > div > div + div + div {
                    font-size: 13px;
                    line-height: 1.1;
                    padding: 5px 0px 0px;
                  }


      .features-bg > div + div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        padding: 75px 0px 70px 50px;
      }
        .features-bg > div + div > div {
          color: transparent;
          font-size: 0px;
          background: url('/i/features-bg.jpg') no-repeat 0% 50%/cover transparent;
          flex-grow: 1;
          position: relative;
        }



    .car-finding-container {
      padding: 0px 0px 40px;
    }
      .car-finding-container > div {
        position: relative;
      }
        .car-finding-container > div:before {
          content: "";
          background: #f4f9fd;
          position: absolute;
          top: 30px;
          left: 0px;
          right: 0px;
          bottom: 0px;

          -webkit-clip-path: polygon(0 110px, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 110px, 100% 0, 100% 100%, 0 100%);
        }

        .car-finding-container > div > .wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
          z-index: 1;
        }
          .car-finding-bg {
            color: transparent;
            font-size: 0px;
            width: 61.36363636%;
            min-width: 61.36363636%;
            padding: 0px 0px 50px;
          }
            .car-finding-bg:after {
              content: "";
              background: url('/i/car-finding-form-bg.png') no-repeat 100% 50%/contain transparent;
              display: block;
              height: 0px;
              padding: 79% 0% 0%;
            }

          .car-finding-form {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 50px 0px 50px 80px;
          }
            .car-finding-form .form-block-container {
              background: transparent;
              flex-grow: 1;
              padding: 0px;
            }



      .faq-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        padding: 0px 0px 40px;
        margin: -22px 0px 0px -22px;
      }
        .faq-list > div {
          width: 50%;
          padding: 22px 0px 0px 22px;
        }
          .faq-list > div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            color: #2884ff;
            font-weight: 500;
            line-height: 1.2;
            background: #fff;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            z-index: 1;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
            .faq-list > div > div:first-child:before {
              content: "";
              border: 1px solid transparent;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;

              -webkit-transition: border-color .2s ease-in-out .2s;
              -moz-transition: border-color .2s ease-in-out .2s;
              -o-transition: border-color .2s ease-in-out .2s;
              transition: border-color .2s ease-in-out .2s;
            }
              .faq-list > div.active > div:first-child:before {
                border-color: #2884ff;

                -webkit-transition: border-color .2s ease-in-out 0s;
                -moz-transition: border-color .2s ease-in-out 0s;
                -o-transition: border-color .2s ease-in-out 0s;
                transition: border-color .2s ease-in-out 0s;
              }

            .faq-list > div > div:first-child > span:first-child {
              padding: 28px 31px;
            }

            .faq-list > div > div:first-child > span + span {
              width: 77px;
              min-width: 77px;
              position: relative;
            }
              .faq-list > div > div:first-child > span + span:before {
                content: "";
                border-left: 1px solid #2884ff;
                position: absolute;
                top: -1px;
                left: 0px;
                right: -99px;
                bottom: -1px;

                -webkit-transition: background .2s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
                -moz-transition: background .2s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
                -o-transition: background .2s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;
                transition: background .2s ease-in-out 0s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -ms-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s;

                -webkit-transform: skewX(-20deg);
                -moz-transform: skewX(-20deg);
                -ms-transform: skewX(-20deg);
                -o-transform: skewX(-20deg);
                transform: skewX(-20deg);
              }
                .faq-list > div > div:first-child:hover > span + span:before,
                .faq-list > div.active > div:first-child > span + span:before {
                  background: #2884ff;
                }
                .faq-list > div.active > div:first-child > span + span:before {
                -webkit-transform: skewX(20deg);
                -moz-transform: skewX(20deg);
                -ms-transform: skewX(20deg);
                -o-transform: skewX(20deg);
                transform: skewX(20deg);
                }

              .faq-list > div > div:first-child > span + span:after {
                content: "";
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: url('/i/faq-list--plus.svg') no-repeat 50% 50%/25px auto transparent;
                font-size: 48px;
                font-weight: 100;
                line-height: 1.1;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

                -webkit-transition: background .2s ease-in-out 0s;
                -moz-transition: background .2s ease-in-out 0s;
                -o-transition: background .2s ease-in-out 0s;
                transition: background .2s ease-in-out 0s;
              }
              .faq-list > div > div:first-child:hover > span + span:after,
              .faq-list > div.active > div:first-child > span + span:after {
                background-image: url('/i/faq-list--plus--white.svg');
              }
              .faq-list > div.active > div:first-child > span + span:after {
                background-image: url('/i/faq-list--minus--white.svg');
              }


            .faq-list > div > div:first-child + div {
              display: none;
              overflow: hidden;
              vertical-align: bottom;
            }
              .faq-list > div > div:first-child + div > div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                height: 100%;
              }
                .faq-list > div > div:first-child + div > div > div {
                  background: #fff;
                  border: solid #2884ff;
                  border-width: 0px 1px 1px;
                  width: 100%;
                  padding: 25px 30px 35px;
                }
  /* Content block end */



  /* Contacts */
  .contacts-container {
    --contacts-width: 100vw;
    position: relative;
  }
    .contacts-container > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
    }
      .contacts-container > .wrapper > div:first-child {
        width: 31.34796238%;
        min-width: 31.34796238%;
        padding: 110px 60px 70px 0px;
        margin: 140px 0px 0px;
        position: relative;
        z-index: 1;
      }
        .contact-page .contacts-container > .wrapper > div:first-child {
          margin: 70px 0px 80px;
        }
          .contact-page .contacts-container > .wrapper > div:first-child {
            align-self: flex-start;
          }

        .contacts-container > .wrapper > div:first-child:before {
          content: "";
          background: #f4f9fd;
          width: calc(100% + ((var(--contacts-width) - 1276px) / 2));
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;

          -webkit-clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
        }

        .contacts-container > .wrapper > div:first-child > div {
          color: #545353;
          position: relative;
          z-index: 1;
        }
          .contacts-logo {
            max-width: 105px;
          }
            .contacts-logo img {
              width: 100%;
            }

          .contacts-phones,
          .contacts-emails,
          .contacts-address {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }
            .contacts-phones:before,
            .contacts-emails:before,
            .contacts-address:before {
              content: "";
              background: no-repeat 50% 0%/contain transparent;
              width: 26px;
              min-width: 26px;
              min-height: 29px;
              margin-top: 8px;
            }

            .contacts-phones > div,
            .contacts-emails > div,
            .contacts-address > div {
              padding-left: 10px;
            }

            .contacts-logo + .contacts-phones {
              padding: 35px 0px 0px;
            }

            .contacts-phones:before {
              background-image: url('/i/contacts-icon-phone.svg');
            }

            .contacts-phone {
              font-size: 24px;
              font-weight: 500;
            }

            .contacts-worktime {
              font-size: 14px;
            }

            .contacts-phones .callback-link {
              font-size: 14px;
            }
              .contacts-phones .callback-link a {
                color: #2884ff;
                text-decoration: underline;
                cursor: pointer;
              }
                .contacts-phones .callback-link a:hover {
                  text-decoration: none;
                }
                .contacts-phones .callback-link a * {
                  display: inline
                }


          .contacts-emails,
          .contacts-address {
            font-size: 20px;
            font-weight: 500;
            padding: 35px 0px 0px;
          }
            .contacts-emails:before {
              background-image: url('/i/contacts-icon-email.svg');
            }

            .contacts-emails > div,
            .contacts-address > div {
              align-self: center;
            }


          .contacts-address {
            line-height: 1.4;
          }
            .contacts-address:before {
              background-image: url('/i/contacts-icon-address.svg');
            }


          .contacts-social {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: center;
            padding: 10px 0px 0px 11px;
          }
            .contacts-social > div {
              padding: 25px 0px 0px 25px;
            }
              .contacts-social a {
                color: transparent;
                font-size: 0px;
                display: block;
                width: 31px;
                height: 31px;
                position: relative;
              }

              .contacts-social a.fb,
              .contacts-social a.ok {
                width: 18px;
              }

                .contacts-social a:after {
                  content: "";
                  display: block;
                  background: url('/i/social-icons-full.svg') no-repeat -7px 0px transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  margin: auto;
                }
                .contacts-social a.tw:after {
                  background-position: 0px -32px;
                }
                .contacts-social a.ig:after {
                  background-position: -32px 0px;
                }
                .contacts-social a.vk:after {
                  background-position: -63px 0px;
                }
                .contacts-social a.ok:after {
                  background-position: -70px -32px;
                }
                .contacts-social a.wa:after {
                  background-position: -96px 0px;
                }
                .contacts-social a.youtube:after {
                  background-position: -32px -32px;
                }
                .contacts-social a.tg:after {
                  background-position: -96px -32px;
                }
                .contacts-social a.viber:after {
                  background-position: -127px 0px;
                }


      .contacts-container > .wrapper > div + div {
        width: 68.65203762%;
        position: relative;
        z-index: 0;
      }
        .contacts-container > .wrapper > div + div > div {
          height: 100%;
          margin-left: -75px;
        }
          .contacts-container > .wrapper > div + div > div > div {
            background: #fff;
            width: calc(100% + ((var(--contacts-width) - 1276px) / 2));
            height: 100%;
            position: relative;

            -webkit-clip-path: polygon(0 0, 100% 90px, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 90px, 100% 100%, 0 100%);
          }
            .contacts-container > .wrapper > div + div > div > div > div {
              content: "";
              display: block;
              height: 0px;
              padding-top: 50%;
            }
              .contacts-container > .wrapper > div + div > div > div > div:before {
                content: "Загрузка карты...";
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                position: absolute;
                top: 70px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 0;
              }

              #contacts-map {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }
                #contacts-map .contacts-map-ground {
                  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'saturate\' values=\'.15\'/></filter></svg>#grayscale");
                  -webkit-filter: grayscale(.85);
                  filter: grayscale(.85);
                }
  /* Contacts end */



  /* Footer */
  .footer-callback-container {
    background: url('/i/footer-callback-bg-line.svg') repeat-x 25% 138px/2100px auto transparent;
    padding: 140px 0px 70px;
    position: relative;
  }
    .footer-callback-container:before {
      content: "";
      background: #2884ff;
      position: absolute;
      top: 150px;
      left: 0px;
      right: 0px;
      bottom: 0px;
    }
    .footer-callback-container:after {
      content: "";
      background: url('/i/footer-callback-bg-dots.svg') repeat-x 0% 100%/auto 342px transparent;
      position: absolute;
      top: 0px;
      left: 50%;
      right: 0px;
      bottom: 70px;
    }

    .footer-callback-container > .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
      .footer-callback-container > .wrapper > div {
        width: 50%;
        position: relative;
        z-index: 1;
      }

      .footer-callback-container > .wrapper > div:first-child {
        color: #fff;
        padding: 70px 40px 25px 0px;
      }
        .footer-callback-container > .wrapper > div:first-child:after {
          content: "";
          background: #f4f9fd;
          display: block;
          width: 70px;
          height: 3px;
          position: absolute;
          left: 0px;
          top: 100%;
        }

        .footer-callback-container > .wrapper > div:first-child .subheader {
          color: #f4f9fd;
          font-size: 60px;
          font-weight: 900;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
          .footer-callback-container > .wrapper > div:first-child .subheader:after {
            display: none;
          }

        .footer-callback-container > .wrapper > div:first-child .footer-callback-phone {
          font-size: 36px;
          font-weight: 900;
          padding: 0px 0px 20px;
        }
          .footer-callback-container > .wrapper > div:first-child .footer-callback-phone a {
            color: #fff;
          }


      .footer-callback-container .form-block-container {
        background: transparent;
        width: 430px;
        min-width: 430px;
        padding: 0px 0px 10px;
        margin: -140px auto 0px;
      }
        .footer-callback-container .form-container {
          width: 100%;
          margin-left: 16.27906977%;
        }
          .footer-callback-container .form-container:after {
            content: "";
            background: url('/i/footer-callback-form-bg-bottom.png') no-repeat 50% 100%/100% auto transparent;
            display: block;
            height: 0px;
            padding: 30% 23.25581395% 0% 0%;
          }

          .footer-callback-container .form-wrapper {
            background: url('/i/footer-callback-form-bg-top.png') no-repeat 50% 0%/100% auto transparent;
            padding: 30% 23.25581395% 1.16279069% 0%;
            position: relative;
            z-index: 1;
          }
            .footer-callback-container .form-wrapper form {
              min-height: 410px;
              padding: 0px 36px;
            }
              .footer-callback-container .form-wrapper .form-label {
                font-size: 15px;
              }

              .footer-callback-container .form-wrapper .form-button,
              .footer-callback-container .form-wrapper .button-style {
                width: 100%;
              }
              .footer-callback-container .form-wrapper .button-style {
                font-size: 17px;
                min-width: unset;
              }


  .footer {
    border-top: 1px solid #f4f9fd;
  }
    .footer-menu ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 25px 0px 0px;
      margin: 0px -18px;
    }
      .footer-menu li {
        flex: 0 1 auto;
        position: relative;
      }
        .footer-menu li a,
        .footer-menu li div > span {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #2884ff;
          font-size: 20px;
          line-height: 1.1;
          font-weight: 300;
          height: 100%;
          padding: 17px 18px 18px;
          text-decoration: none;
          white-space: nowrap;
        }
        .footer-menu li a {
          cursor: pointer;
        }

          .footer-menu li.item-spec a,
          .footer-menu li.item-spec div span {
            color: #ef0700;
            font-weight: 400;
          }

          .footer-menu li a span {
            border-bottom: 1px solid transparent;

            -webkit-transition: border-color .2s ease-in-out 0s;
            -moz-transition: border-color .2s ease-in-out 0s;
            -o-transition: border-color .2s ease-in-out 0s;
            transition: border-color .2s ease-in-out 0s;
          }
            .footer-menu li a:hover span {
              border-bottom-color: #2884ff;
            }
            .footer-menu li.item-spec a:hover span {
              border-bottom-color: #ef0700;
            }

          .footer-menu li.active a {
            font-weight: 500;
          }


    .footer-bottom {
      color: #909090;
      font-size: 13px;
      padding: 0px 0px 25px;
    }
      .footer-bottom > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0px 0px 0px -18px;
      }
        .footer-bottom > div > div {
          flex: 1 1 auto;
          padding: 18px 0px 0px 18px;
        }
  /* Footer end */
/* Structure end */